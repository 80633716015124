.vaccination-body{
    height: 100vh !important;
    background-color: white;
    padding: 2rem 2rem 2rem 2rem;
}

.vaccination-input{
    width: 100% !important;
    height: 3rem;
    border: 1.3px solid rgb(36, 177, 253);
    margin-bottom: 1.3rem;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 13px !important;
}

.vaccination-input::-webkit-input-placeholder{
    color: #7B7B7B;
    font-family: 'Noto Sans JP', sans-serif;
}

.vaccination-body .save-vaccination,
.vaccination-body .btn-saveschedule{
    width: 100% !important;
    padding: 0.7rem !important;
    background-color: #039BEB !important;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
    margin-top: 0rem;
}


.vaccination-body .css-26l3qy-menu{
    margin-top: 3rem;
    width: 100% !important;
    margin-left: 0.7rem;
}

.vaccination-body .merk-vaccine .css-26l3qy-menu{
    margin-top: 0rem;
    width: 100% !important;
    margin-left: 0rem;
}

.vaccination-body .merk-vaccine .css-1uccc91-singleValue{
    font-size: 13px !important;
}
.vaccination-body .react-datepicker-wrapper{
    width: 100% !important;
}
.vaccination-body .react-datepicker__input-container input{
    margin: auto auto 1rem auto !important;
    width: 100% !important;
    height: 3rem !important;
    border: 1.3px solid rgb(36, 177, 253);
    font-size: 12px !important;
    padding-left: 8px !important;
    color:#4b4a4a !important;
    
    
}
.vaccination-body .css-yk16xz-control,
.vaccination-body .css-1pahdxg-control
{
    margin: auto auto 1rem auto !important;
    width: 100% !important;
    height: 3rem !important;
    border: 1.3px solid rgb(36, 177, 253);
}
.vaccination-body .react-datepicker__input-container input::-webkit-input-placeholder{
    font-size: 12px !important;
    font-family: 'Noto Sans JP', sans-serif;
}

.vaccination-body .select-placeholder{
    color:#A3A1A1;
    font-size: 12px;
    margin-top: 15px;
    font-weight:500;
    
}

.vaccination-body .delete-vaccination-button{
    float: right;
    margin-bottom: 10px;
    margin-top: -1rem;
}

.vaccination-body .delete-vaccination-icon{
    font-size: 12px;
}