.dropdown-vaccines{
    width: 15rem;
    height: 2.3rem;
    border: 1px solid black !important;
    border-radius: 3px;
    padding-left: 1rem;
}

.option-text{
    text-align: center;
}

