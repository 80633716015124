@media (min-width:1224px) {
.family-members{
    padding-left: 0.5rem;
    color: #434343;
    font-size: 14px;
    font-weight: bold;
}

.parent-data{
    font-size: 14px;
    color : #434343;
    font-weight: 500;
}

.detail-table{
    margin-top: -3rem;
    font-family: 'Roboto';
    padding-bottom: 5rem; 
}

.btn-add-member{
    float: right;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.detail-buttons{
    text-align: center;
}
.detail-buttons button{
    width: 5rem;
    height: 2rem;
    font-size: 12px;
    margin-right: 0.5rem;
}

.detail-buttons .patient-checkup{
    width: 8.5rem;
}
}

@media(min-width: 1879px) and (min-width: 800px){
    .parent-data{
        font-size: 1rem;
        color : #434343;
        font-weight: 500;
    }
    .family-members{
        font-size: 1rem;
        font-weight: bold;
        display: inline-block;
    }
    .patient-checkup{
        width:8.7rem !important;
        font-size:11px !important;
    }
}