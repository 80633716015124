@media (min-width: 700px) {
  .patient-page-title {
    font-size: 1.3rem !important;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }
  .patient-page-subtitle {
    text-align: left;
    font-size: 1rem !important;
    text-align: left;
  }
  .add-button button {
    font-size: 12px;
  }
  .btn-add-table {
    width: 12rem;
    font-size: 14px;
    float: right;
    margin-left: 1rem !important;
  }
  #pageDropDown {
    background-color: #039beb;
    border: #039beb;
    margin-right: 1rem;
  }
  .patient-content .pagination {
    float: right !important;
  }

  .search-label {
    float: right;
  }

  .patient-content .react-bootstrap-table {
    font-size: 14px;
  }

  .table-buttons button {
    font-size: 12px;
    width: 6rem;
    margin-left: 5px;
    /* display: none; */
  }

  .row {
    width: 105% !important;
    margin-left: -5rem !important;
  }

  .pagination {
    margin-left: 10rem;
  }

  #pageDropDown {
    font-size: 12px !important;
    margin-left: 5rem !important;
  }

  .pagination {
    font-size: 10px !important;
  }

  .react-bootstrap-table-pagination-total {
    font-size: 12px !important;
  }
}

@media (min-width: 1224px) {
  .patient-page-title {
    font-size: 1.3rem !important;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }
  .patient-page-subtitle {
    text-align: left;
    font-size: 1rem !important;
    text-align: left;
  }
  .add-button button {
    font-size: 12px;
  }
  .btn-add-table {
    width: 12rem;
    font-size: 14px;
    float: right;
    margin-left: 1rem !important;
  }
  #pageDropDown {
    background-color: #039beb;
    border: #039beb;
    margin-right: 1rem;
  }
  .patient-content .pagination {
    float: right !important;
  }

  .search-label {
    float: right;
  }

  .patient-content .react-bootstrap-table {
    font-size: 14px;
  }

  .table-buttons button {
    font-size: 12px;
    width: 4rem;
    margin-left: 5px;
    display: initial;
  }

  .table-buttons2 button {
    font-size: 12px;
    width: 6rem;
    margin-left: 5px;
    /* display: none; */
  }
  .row {
    width: 100% !important;
    margin-left: -5rem !important;
  }

  .pagination {
    margin-left: 19rem;
  }

  #pageDropDown {
    font-size: 12px !important;
    margin-left: 5rem !important;
  }

  .pagination {
    font-size: 10px !important;
  }

  .react-bootstrap-table-pagination-total {
    font-size: 12px !important;
  }

  .input-search {
    width: 20rem;
    height: 2.5rem;
    font-size: 14px;
    float: right;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding-left: 1rem;
  }
  .btn-search {
    width: 1.5rem;
    float: right;
    margin-top: 0.5rem;
    margin-left: 0rem;
    position: absolute;
    left: 95%;
    cursor: pointer;
  }
}

@media (min-width: 1879px) and (min-width: 800px) {
  .add-button button {
    font-size: 14px;
  }
  .patient-page-title {
    font-size: 1.5rem;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }
  .patient-page-subtitle {
    text-align: left;
    font-size: 1.1rem;
    text-align: left;
  }

  #pageDropDown {
    background-color: #039beb;
    border: #039beb;
  }
  .row {
    width: 80% !important;
  }

  .pagination {
    margin-left: 39rem;
  }

  .input-search {
    width: 20rem;
    height: 2.5rem;
    font-size: 14px;
    float: right;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding-left: 1rem;
  }

  .btn-search {
    width: 1.5rem;
    float: right;
    margin-top: 0.5rem;
    margin-left: 0rem;
    position: absolute;
    left: 95%;
    cursor: pointer;
  }
}
