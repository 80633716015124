.span-style{
    margin-right: 2rem;
    margin-left: 10rem;
}

.change-data-buttons{
    margin-top: 0rem;
}

.change-data-buttons button{
    width: 7rem;
}

.change-data-buttons{
    margin-left: 9rem !important;
}

.react-datepicker__input-container input{
    margin-left: 1rem;
    width: 15rem;
    height: 2.2rem !important;
    border: 1px solid black;
    border-radius: 3px;
    padding: 1rem;
}

.patient-content .add-fam-date{
    border: 1px solid black !important;
}