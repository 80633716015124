.container-self-checkin {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 50px 0;
  background: url(../../assets/images//img-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
  z-index: 999;
}

.self-checkin-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.self-checkin-title {
  text-align: center;
  font-weight: 800;
  color: #ffffff;
}

.self-checkin-subtitle {
  color: #ffffff;
  max-width: 936px;
}

.card-wrapper {
  display: flex;
  gap: 64px;
}

@media (min-width: 768px) {
  .self-checkin-title {
    font-size: 38px;
    line-height: 48px;
    max-width: 600px;
    margin-bottom: 20px;
  }

  .self-checkin-subtitle {
    font-size: 24px;
    line-height: 36px;
    max-width: 700px;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .self-checkin-title {
    font-size: 38px;
    max-width: 600px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .self-checkin-subtitle {
    font-size: 24px;
    line-height: 36px;
    max-width: 700px;
    margin-bottom: 70px;
  }
}

@media (min-width: 1200px) {
  .self-checkin-title {
    font-weight: 800;
    font-size: 38px;
    max-width: 600px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .self-checkin-subtitle {
    font-size: 24px;
    line-height: 36px;
    max-width: 700px;
    margin-bottom: 60px;
  }
}
