
@media (min-width:1224px) {
    .active-queue-buttons{
        margin-bottom: 3rem;
    }
    .active-queue-table td{
        font-size: 14px;
    }
    .active-queue-buttons button{
        width: 8rem;
        font-size: 14px;
        height: 2.5rem;
        margin-right: 1rem;
        margin-top: 2rem;
        float: right;
    }
    .note-child-title{
        font-size: 1.2rem;
        font-family: 'Roboto';
        margin-top: 1rem;
    }
    
    .note-text{
        font-size:1rem;
        font-family: 'Roboto';
    }
    
.modal-content{
    width: 50rem;
    margin-top:0%;
    margin-left:-25%!important;
}

.btn-worker{
    float:right;
}
}

@media(min-width: 1879px) and (min-width: 800px){

.active-queue-buttons{
    margin-bottom: 3rem;
}
.active-queue-buttons button{
    width: 10rem;
    font-size: 1rem;
    height: 3rem;
    margin-right: 1rem;
    margin-top: 2rem;
    float: right;
    margin-bottom: 3rem;
}

.btn-worker{
    float:right;
}
.modal-backdrop.show{
    width:100%;
    height:100%;
}



.note-child-title{
    font-size: 1.9rem;
    font-family: 'Roboto';
    margin-top: 1rem;
}

.note-text{
    font-size:1.2rem;
    font-family: 'Roboto';
}

.note-button{
    width:5rem;
    font-size:1rem;
}

.modal-footer{
    height:6rem;
}

.vaccination-data .vaccine-label{
    font-size:1.3rem;
    font-weight: 500;
    width:15rem;
}

.vaccination-data .vaccine-text{
    font-size:1.3rem;
    font-weight: 500;
}
}

.container-wrapper-receipt {
    position: relative;
    max-width: 13em;
}

.image-payment {
    width: 13em;
    height: auto;
    border: 1px solid #00000038;
    cursor: pointer;
    opacity: 1;
    transition: .5s ease;
}

.middle-receipt {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container-wrapper-receipt:hover .image-payment {
    border: 1px solid #039beb;
    opacity: 0.75;
}

.container-wrapper-receipt:hover .middle-receipt {
    opacity: 1
}

.confirm-payment-radio {
    padding: .5em 2em .5em 3em
}

.confirm-payment-radio input:checked {
    color: green
}

.form-payment-confirm {
    padding: 1em 12em;
}

.choice-refused-payment {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s, overflow 0s;
}

.show-choice-refused {
  max-height: 20em;
  overflow:auto;
  transition: max-height 0.5s, overflow 0.5s 0.5s;
}
