.container-failed-checkin {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.wrapper-failed-checkin {
  background: #fff;
  padding: 48px 64px;
  border-radius: 20px;
  width: 579px;
}

.img-wrapper-failed-checkin {
  margin-bottom: 24px;
}

.title-failed-checkin {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #232323;
  margin: 0;
  margin-bottom: 58px;
}

.sub-title-failed-checkin {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  color: rgba(35, 35, 35, 0.7);
  margin-bottom: 48px;
  text-align: left;
}

.button-failed-checkin {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: none;
  background: #f06b10;
  margin-top: 24px;
}

.button-failed-checkin:hover {
  background: #f06a109c;
}
