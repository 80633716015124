.account-title{
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
}

.add-user-table td label{
    font-size: 15px;
    font-family: 'Roboto';
}

.add-user-table .css-26l3qy-menu{
    width: 15.2rem !important;
    margin-left: 1rem;
    margin-top: 1rem;
}


.data-account button{
    width: 7rem;
    height: 2.3rem;
    font-size: 1rem;
    margin-top: 3rem;
}

.data-account .save-user{
    margin-left: 9.5rem;
}