@media (min-width: 1224px) {
  .time-picker-style input {
    margin-left: 1rem;
    width: 30rem;
    height: 2.4rem !important;
    /* border: 1px solid black !important; */
    border-radius: 3px;
    /* padding: 1rem; */
  }

  .ant-time-picker-panel-input {
    font-size: 1.2rem;
  }
  .ant-time-picker-panel-inner {
    width: 31rem !important;
    margin-left: 0.8rem;
    /* margin-top: 1rem; */
    font-size: 1rem;
  }
  .ant-time-picker-panel-select {
    width: 10rem !important;
    font-size: 1.2rem !important;
  }

  .ant-time-picker-icon {
    display: none;
  }
  body {
    background-color: #e9eaec;
  }

  .shift-buttons button {
    width: 7rem;
    height: 2rem;
    font-size: 12px;
    margin-top: 3rem;
  }

  .add-shift-table .css-26l3qy-menu {
    width: 15.2rem !important;
    margin-left: 0rem;
    background-color: white;
  }

  .add-shift-table .css-2b097c-container {
    margin-left: 1rem !important;
  }
  /* .ant-picker {
    width: 30rem !important;
    height: 2.5rem !important;
    margin-left: 1rem !important;
    border: 1px solid black !important;
    padding-left: 0 !important;
  } */
}
@media (min-width: 1879px) and (min-width: 800px) {
  .ant-time-picker-icon {
    display: none;
  }
  /* .ant-picker {
    width: 30rem !important;
    height: 2.5rem !important;
    margin-left: 1rem !important;
    border: 1px solid black !important;
    padding-left: 0 !important;
  } */
  .ant-time-picker-panel-input {
    font-size: 1rem;
  }
  .ant-time-picker-panel-inner {
    width: 31rem !important;
    margin-left: 0.8rem;
    /* margin-top: 1rem; */
    font-size: 12px;
  }
  .ant-time-picker-panel-select {
    width: 10rem !important;
    font-size: 14px !important;
  }

  body {
    background-color: #e9eaec;
  }

  .shift-buttons button {
    width: 7rem;
    height: 2rem;
    font-size: 12px;
    margin-top: 3rem;
  }

  .add-shift-table .css-26l3qy-menu {
    width: 15.2rem !important;
    margin-left: 0rem;
    background-color: white;
  }

  .add-shift-table .css-2b097c-container {
    margin-left: 1rem !important;
  }
}
