

@media (min-width:1224px) {
    #textNote{
        position: absolute;
        margin-left: -30rem;
        font-size:10px;
        margin-top: -2rem;
    
    }
}

@media(min-width: 1879px) {
    #textNote{
        position: absolute;
        margin-left: -30rem;
        margin-top: -3rem;
        font-size: 14px;
    }
}