
.code-body{
    background-color: white;
    height: 100vh !important;
    margin-top: 20%;
}

.qrcode-style{
    width: 14rem !important;
    height: 14rem !important;
}

.qrcode-text{
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    margin-top: 1rem;
}

.qrcode-text2{
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-top: 1rem;
    letter-spacing: 2px;
}

.token-line{
    width: 90%;
    margin-top:-2rem;
    border: 1px solid #D1D1D1;
}

.token-text{
    font-size:2rem;
    letter-spacing: 2px;
    color: #003758;
    font-weight: bold;
    font-family: 'Roboto';
}

@media (min-width:700px) {

    .code-body{
        background-color: white;
        height: 100vh !important;
        margin-top: 0%;
        padding-top: 3%;
    }
    
    .qrcode-style{
        width: 20rem !important;
        height: 20rem !important;
    }
    
    .qrcode-text{
        font-size: 1.1rem;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        margin-top: 1rem;
    }
    
    .qrcode-text2{
        font-size: 1rem;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        margin-top: 1rem;
        letter-spacing: 2px;
    }
    
    .token-line{
        width: 80%;
        margin-top:-2rem;
        border: 1px solid #D1D1D1;
    }
    
    .token-text{
        font-size:2rem;
        letter-spacing: 2px;
        color: #003758;
        font-weight: bold;
        font-family: 'Roboto';
    }
    .token-subtitle{
        font-size: 1.1rem;
        color: #D1D1D1;
    }
}

@media (min-width:1224px) {
    .code-body{
        background-color: white;
        height: 100vh !important;
        margin-top: 0%;
        padding-top: 7%;
    }
    .qrcode-style{
        width: 20rem !important;
        height: 20rem !important;
    }
    
    .qrcode-text{
        font-size: 1rem;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        margin-top: 1rem;
    }
    
    .qrcode-text2{
        font-size: 1.2rem;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        margin-top: 1rem;
        letter-spacing: 2px;
    }
    
    .token-text{
        font-size:2rem;
        letter-spacing: 2px;
        color: #003758;
        font-weight: bold;
        font-family: 'Roboto';
    }
    
    .token-line{
        width: 50%;
        margin-top:-2rem;
        border: 1px solid #D1D1D1;
    }
    
    .token-subtitle{
        font-size: 14px;
        color: #D1D1D1;
    }
}
@media(min-width: 1879px) and (min-width: 800px){
.code-body{
    background-color: white;
    height: 100vh !important;
    margin-top: 0%;
    padding-top: 8%;
}
.qrcode-style{
    width: 20rem !important;
    height: 20rem !important;
}

.qrcode-text{
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    margin-top: 1rem;
}

.qrcode-text2{
    font-size: 1.2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-top: 1rem;
    letter-spacing: 2px;
}

.token-text{
    font-size:2rem;
    letter-spacing: 2px;
    color: #003758;
    font-weight: bold;
    font-family: 'Roboto';
}

.token-line{
    width: 50%;
    margin-top:-2rem;
    border: 1px solid #D1D1D1;
}

.token-subtitle{
    font-size: 14px;
    color: #D1D1D1;
}
}