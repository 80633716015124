.add-vaccine-container {
  padding-right: 2rem;
  background: rgb(233, 234, 236);
  margin-top: -2rem;
  margin-left: -6rem;
  padding-top: 2rem;
  padding-left: 7rem;
  height: calc(100vh - 48px);
}

.add-vaccine-container .add-form-wrapper {
  margin-top: 16px;
  background: white;
  padding: 16px;
}
