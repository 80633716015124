.navbar-body{
    height: 4rem;
    background-color: #039BEB;
    padding: 1rem;
}

.navbar-title{
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    margin-top: 0.3rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    color: white;
}

.back-icon{
    color: white;
    font-size: 1.3rem;
    float: left;
    margin-top: 0.3rem;
}