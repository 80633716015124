@media(min-width:1224px){
    .dropdown-vaccines{
        width: 30rem !important;
        height: 2.4rem !important;
        border: 1px solid black !important;
        border-radius: 3px;
        padding-left: 1rem;
        margin-left: 1rem;
        font-size: 14px;
    }
    
    .option-text{
        text-align: center;
    }
    
}

@media(min-width: 1879px) and (min-width: 800px){
.dropdown-vaccines{
    width: 30rem !important;
    height: 3rem !important;
    border: 1px solid black !important;
    border-radius: 3px;
    padding-left: 1rem;
    margin-left: 1rem;
}

.option-text{
    text-align: center;
}

}