.registered-style{
    border: 2px solid red;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: red;
    border-radius: 2px;
}
.confirmed-style{
    border: 2px solid #FA9000;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #FA9000;
    border-radius: 2px;
}

.done-style{
    border: 2px solid #039BEB;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #039BEB;
    border-radius: 2px;
}

.passed-style{
    border: 2px solid #904ebf;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #904ebf;
    border-radius: 2px;
}

.process-style{
    border: 2px solid #00BF16;
    width: 6rem;
    font-size: 11px;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #00BF16;
    border-radius: 2px;
}

.checkedin-style{
    border: 2px solid tomato;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: tomato;
    border-radius: 2px;
}
.emergency-style{
    border: 2px solid red;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: red;
    border-radius: 2px;
}
.text-filter {
    display: none!important;
}

.filter-payment-cust {
    display: grid; 
    gap: .5em;
    margin-bottom: .5em;
    justify-content: center;
    grid-template-columns: auto auto;
}


/* .registered-style p, 
.confirmed-style p,
.done-style p,
.passed-style p,
.process-style p,
.checkedin-style p
.emergency-style p{
    text-align: center;
    font-size: 13px;
    line-height: 2rem;
} */
@media (min-width:1224px) {
    .queue-page-title{
        font-size: 1.4rem;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .queue-page-subtitle{
        text-align: left;
        font-size: 1.1rem;
        text-align: left;
    }
    
    .queue-body{
        width: 110rem;
        background-color: white;
        margin:auto;
        padding: 2rem;
        margin-top: -3rem;
    }
    
    .queue-body-title{
        font-size: 1rem;
        text-align: left;
        margin-left: 0.5rem;
    }
    
    .queue-warning-text{
        position: absolute;;
        top:65%;
        left:31%;
        color:red;
        font-size: 14px;
    }

    .queue-patient-content .react-datepicker__input-container input{
        font-size:14px;
        border: 1px solid #C4C4C4;
    }
    
    .react-datepicker__input-container{
        margin-bottom:1rem;
    }
    .number-title{
        font-size: 7rem;
        font-weight: bold;
    }
    
    .number-subtitle{
        color:#8D8D8D;
        margin-top: -1rem;
        font-size: 14px;
    }
    
    .btn-come1, .btn-come2{
        font-size: 14px;
        width: 10rem;
        margin-top: 0rem;
    }
    
    .btn-come1{
        margin-right: 1rem;
    }
    
    .queues-buttons button{
        width: 5rem !important;
        height: 2rem !important;
        font-size: 12px !important;
        margin-right: 0.5rem !important;
    }
    
    
    /*  */
    
    .registered-style{
        border: 2px solid red;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: red;
        border-radius: 2px;
    }
    .confirmed-style{
        border: 2px solid #FA9000;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: #FA9000;
        border-radius: 2px;
    }
    
    .done-style{
        border: 2px solid #039BEB;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: #039BEB;
        border-radius: 2px;
    }
    
    .passed-style{
        border: 2px solid #904ebf;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: #904ebf;
        border-radius: 2px;
    }
    
    .process-style{
        border: 2px solid #00BF16;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: #00BF16;
        border-radius: 2px;
    }
    
    .checkedin-style{
        border: 2px solid tomato;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: tomato;
        border-radius: 2px;
    }
    .emergency-style{
        border: 2px solid red;
        width: 6rem;
        font-size: 11px;
        height: 2.3rem;
        background-color: white;
        color: red;
        border-radius: 2px;
    }
    /* .registered-style p, 
    .confirmed-style p,
    .done-style p,
    .passed-style p,
    .process-style p,
    .checkedin-style p
    .emergency-style p{
        text-align: center;
        font-size: 13px;
        line-height: 2rem;
    } */
    
    .queue-management #search-bar-0{
        margin-left: 70rem;
        margin-top: -2.5rem;
        float: left;
    }
    
    .queue-management .btn-add-queue{
        width: 13rem;
        height: 2.5rem;
        margin-left: 1rem !important;
        margin-top: -3rem;
        font-size: 14px !important;
    }
    
    .patient-section .dropdown-menu.show{
        top: 0rem ;
        left: -0.2rem ;
    }
    .date-filter .react-datepicker-wrapper{
        float: right !important;
        margin-top: -2rem;
        margin-right: -2rem;
    }
    .date-filter .react-datepicker-wrapper input{
        border: 1px solid #CBCBCB;
        font-size: 14px;
    }
    
}

@media(min-width: 1879px) and (min-width: 800px){
.queue-page-title{
    font-size: 1.4rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.queue-page-subtitle{
    text-align: left;
    font-size: 1.1rem;
    text-align: left;
}

.queue-body{
    width: 110rem;
    background-color: white;
    margin:auto;
    padding: 2rem;
    margin-top: -3rem;
}

.queue-body-title{
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
}

.date-filter .react-datepicker-wrapper{
    float:right;
    margin-top: -2rem;
    margin-right: -2rem;
}

.date-filter .react-datepicker-wrapper input{
    border: 1px solid #CBCBCB;
}

.queue-warning-text{
    position: absolute;;
    top:65%;
    left:40%;
    color:red;
}

.number-title{
    font-size: 8rem;
    font-weight: bold;
}

.number-subtitle{
    color:#8D8D8D;
    margin-top: -1rem;
    font-size: 14px;
}

.btn-come1, .btn-come2{
    font-size: 14px;
    width: 10rem;
    margin-top: 0.5rem;
}

.btn-come1{
    margin-right: 1rem;
}

.queues-buttons button{
    width: 5rem !important;
    height: 2rem !important;
    font-size: 12px !important;
    margin-right: 0.5rem !important;
}


/*  */

.registered-style{
    border: 2px solid red;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: red;
    border-radius: 2px;
}
.confirmed-style{
    border: 2px solid #FA9000;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #FA9000;
    border-radius: 2px;
}

.done-style{
    border: 2px solid #039BEB;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #039BEB;
    border-radius: 2px;
}

.passed-style{
    border: 2px solid #904ebf;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #904ebf;
    border-radius: 2px;
}

.process-style{
    border: 2px solid #00BF16;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: #00BF16;
    border-radius: 2px;
}

.checkedin-style{
    border: 2px solid tomato;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: tomato;
    border-radius: 2px;
}
.emergency-style{
    border: 2px solid red;
    width: 6rem;
    font-size: 11px;
    height: 2.3rem;
    background-color: white;
    color: red;
    border-radius: 2px;
}
/* .registered-style p, 
.confirmed-style p,
.done-style p,
.passed-style p,
.process-style p,
.checkedin-style p,
.emergency-style p{
    text-align: center;
    font-size: 13px;
    line-height: 2rem;
} */



.queue-management #search-bar-0{
    margin-left: 70rem;
    margin-top: -2.5rem;
    float: left;
}

.queue-management .btn-add-queue{
    width: 13rem;
    height: 2.5rem;
    margin-left: 1rem !important;
    margin-top: -3rem;
    font-size: 14px !important;
}

.patient-section .dropdown-menu.show{
    top: 0rem ;
    left: -2.8rem ;
}
}