.nurse-modal .modal-content{
  margin-left: 0rem !important;
  width: 100% !important;
  margin-top: 10rem !important;
}

.nurse-modal .note-child-title{
  font-size: 1rem !important;
}

.vaccination-modal .modal-body{
  margin:0 !important;
}


.btn-setschedule{
  width: 100% !important;
  height: 3rem;
  margin-bottom: 1rem;
  background-color: #fff !important;
  border: 1px solid #039beb !important;
  color: #039beb !important;
  font-size: 13px;
  font-family: "Roboto";
  cursor: pointer !important;
}

.vaccination-body .vaccination-input{
  width: 100% !important;
  height: 3rem;
  border: 1.3px solid rgb(36, 177, 253);
  margin-bottom: 1.3rem;
  border-radius: 5px;
  padding-left: 8px;
}

.vaccination-input::-webkit-input-placeholder{
  color: #7B7B7B;
  font-size: 11px;
  font-family: 'Noto Sans JP', sans-serif;
}

.vaccination-modal .save-vaccination,
.vaccination-modal .btn-saveschedule{
  width: 100% !important;
  padding: 0.7rem !important;
  background-color: #039BEB !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  margin-top: 0rem;
}


.vaccination-modal .css-26l3qy-menu{
  margin-top: 3rem;
  width: 100% !important;
  margin-left: 0.7rem;
}

.vaccination-modal .merk-vaccine .css-26l3qy-menu{
  margin-top: 0rem;
  width: 100% !important;
  margin-left: 0rem;
}

.vaccination-modal .react-datepicker-wrapper{
  width: 100% !important;
}
.vaccination-modal .react-datepicker__input-container input{
  margin: auto auto 1rem auto !important;
  width: 100% !important;
  height: 3rem !important;
  border: 1.3px solid rgb(36, 177, 253);
  font-size: 13px !important;
  padding-left: 8px;
}
.vaccination-modal .css-yk16xz-control,
.vaccination-modal .css-1pahdxg-control
{
  margin: auto auto 1rem auto !important;
  width: 100% !important;
  height: 3rem !important;
  border: 1.3px solid rgb(36, 177, 253);
  
}
.vaccination-modal .react-datepicker__input-container input::-webkit-input-placeholder{
  
  font-family: 'Noto Sans JP', sans-serif;
}

.vaccination-modal .select-placeholder{
  color:#B4A6A6;
  font-size: 12px;
  margin-top: 15px;
  font-weight:500;
}

.vaccination-modal .delete-vaccination-button{
  float: right;
  margin-bottom: 10px;
  margin-top: -1rem;
}

.vaccination-modal .delete-vaccination-icon{
  font-size: 12px;
}

.vaccination-modal  .css-1uccc91-singleValue{
  font-size: 13px !important;
}