.modern-radio-container {
  margin: 24px 0;
  display: flex;
  cursor: pointer;
  user-select: none;
}

.selected-text {
    transition: all 0.1s linear;
    color: #3FBC07
}

.radio-outer-circle {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid #3FBC07;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
}

.radio-inner-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #3FBC07;
  transition: all 0.1s linear;
}
.unselected {
  border: 2px solid #666;
}
.unselected-circle {
  width: 0;
  height: 0;
}
.helper-text {
  /* color: #666; */
  padding-right: 8px;
}

.input-radio {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #666;
}

.selected-input {
  border-bottom: 1px solid #3FBC07;
  transition: all 0.2s linear;
}
