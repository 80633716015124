@media(min-width:1224px){
    .add-vaccine-label{
        font-size: 14px !important;
        width: 10rem;
        
    }
    
    .patient-content .Daftar Sus {
        margin-left: 0rem !important;
        width: 30rem !important;
        height: 3rem !important;
        font-size: 1.1rem;
    }
    .add-table .css-yk16xz-control,
    .add-table .css-1pahdxg-control
    {
        height: 2rem;
        margin-top: -1.5rem;
        margin-left: 0.3rem;
        border: 1px solid black !important;
        font-size: 14px;
        width: 30rem;
    }

    .add-table .css-26l3qy-menu{
        width: 30rem !important;
        margin-left: 0.5rem !important;
        background-color: white;
        margin-top: 0rem !important;
        font-size: 14px;
    }
    

    .add-table .react-datepicker__input-container{
        margin-left: 0rem !important;
    }
    
    .add-table .css-2b097c-container{
        margin-left: 1rem !important;
    }
    .add-table .react-datepicker__input-container input{
        width:30rem !important;
        margin-left: 1rem !important;
        height:2.4rem !important;
        font-size: 14px;
    }
    .add-table .valid-feedback{
        margin-left: 1.1rem;
        margin-top: 0.2rem;
        font-size: 14px;
    }
    .add-table .invalid-feedback{
        margin-left: 1.3rem;
        margin-top: 0.2rem;
        font-size: 14px;
    }

    .add-table label{
        font-size: 14px;
    }
}
@media(min-width: 1879px) and (min-width: 800px){

.add-fam-table input, .add-fam-table .add-fam-date{
    border: 1px solid black !important;
}


.label-class label{
    width: 10rem;
}

.add-table .css-2b097c-container .css-26l3qy-menu{
    width: 30rem !important;
    margin-left: 0.3rem !important;
    margin-top: 0rem !important;
}

.add-fam-table .invalid-feedback{
    margin-top: 1rem;
    margin-left: 1rem;
}

.patient-content .css-yk16xz-control, .patient-content .css-yk16xz-control, .patient-content .select-selector .css-2b097c-container, .patient-content .css-1pahdxg-control{
    margin-left: 0.1rem !important;
    width: 30rem !important;
    height: 3rem !important;
}

.patient-content .add-fam-date {
    margin-left: 1rem !important;
    width: 30rem !important;
    height: 3rem !important;
}

.add-table .css-yk16xz-control,
.add-table .css-1pahdxg-control
{
    height: 3rem;
}

.add-vaccine-label{
    font-size: 1rem !important;

    
}
.add-table .react-datepicker__input-container input{
    width:30rem !important;
    margin-left: 1rem !important;
    height:3rem !important;
    font-size: 1rem;
}
.add-table .react-datepicker__input-container{
    width:30rem !important;
    margin-left: 0rem !important;
}

.add-table .valid-feedback{
    margin-left: 1.1rem;
    margin-top: 0.2rem;
    font-size: 14px;
}
.add-table .invalid-feedback{
    margin-left: 1.1rem;
    margin-top: 0.2rem;
    font-size: 14px;
}


}

