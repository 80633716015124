.schedule-page-title{
    font-size: 1.4rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.schedule-page-subtitle{
    text-align: left;
    font-size: 1.1rem;
    text-align: left;
}


.btn-add-schedule{
    float: right;
    font-size: 14px;
    margin-top: -4rem;
    width: 10rem;
    background-color: #00BF16;
}