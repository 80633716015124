.measure-body {
  height: 100vh;
  background-color: white;
  padding: 2rem;
}

.measure-input {
  width: 100% !important;
  height: 3rem;
  border: 1.3px solid rgb(36, 177, 253);
  margin-bottom: 1.3rem;
  border-radius: 5px;
  padding-left: 8px;
  font-size: 12px !important;
  color: #4b4a4a !important;
}

.measure-input::-webkit-input-placeholder {
  color: #4b4a4a !important;
  font-size: 12px !important;
  font-family: "Noto Sans JP", sans-serif;
}
.measure-body .react-datepicker-wrapper {
  width: 100% !important;
}

.measure-body .measure-button {
  width: 100% !important;
  padding: 0.7rem;
  background-color: #039beb;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
}

.measure-body .react-datepicker__input-container input {
  margin: auto auto 1rem auto !important;
  width: 100% !important;
  height: 3rem !important;
  border: 1.3px solid rgb(36, 177, 253);
  font-size: 13px !important;
  padding-left: 8px !important;
}
.measure-body
  .react-datepicker__input-container
  input::-webkit-input-placeholder {
  font-size: 13px !important;
  font-family: "Noto Sans JP", sans-serif;
}

.nurse-overlay.css-42igfv {
  margin-top: 80% !important;
}

.nurse-overlay .css-df17o1 {
  height: 100% !important;
}
