.wrapper-custom {
    display: block;
    width: 100%;
    margin-bottom: 1em;
}

.toolbar-custom {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 12px;
    margin-bottom: 5px;
    user-select: none;
}

.editor-custom {
    height: 20em;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.rdw-option-active {
    background: #049beb5c;
}
