.certificate-container {
  padding-right: 2rem;
  background: white;
  margin-top: -2rem;
  margin-left: -6rem;
  padding-top: 2rem;
  padding-left: 7rem;
  height: 100vh;
}

.certificate-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certificate-header-wrapper.certificate-title-wrapper {
}
