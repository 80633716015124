.detail-request-certificate-container {
  padding-right: 2rem;
  background: white;
  margin-top: -2rem;
  margin-left: -6rem;
  padding-top: 2rem;
  padding-left: 7rem;
  min-height: 100vh;
  padding-bottom: 40px;
}

/* Style for modal success send certificate */
.modal-success-send-certificate > .ant-modal-content {
  border-radius: 24px;
}

.modal-success-send-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-success-send-description {
  width: 400px;
  text-align: center;
  color: #747474;
  font-size: 16px;
}

.img-success-send-wrapper {
  width: 100px;
  height: 100px;
}

.img-success-send-wrapper > img {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  object-fit: cover;
}

/* Style for modal reject request certificate */
.modal-input-reason > .ant-modal-content {
  border-radius: 24px;
}

.modal-reason-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-reason-description {
  width: 400px;
  text-align: center;
  color: #747474;
  font-size: 16px;
}

.modal-reason-description > span {
  font-weight: 700;
}

.img-reason-wrapper {
  width: 100px;
  height: 100px;
}

.img-reason-wrapper > img {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  object-fit: cover;
}

.modal-upload-certificate {
  width: 717px !important;
  border-radius: 10px !important;
}

.modal-upload-certificate-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-upload-certificate-image {
  margin-top: 34px;
}

.modal-upload-certificate-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.modal-upload-certificate-desc {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(19, 44, 86, 0.6);
}

.modal-upload-certificate-drag-wrapper {
  width: 100% !important;
  background: #f4faff !important;
  height: 242px !important;
  border: none !important;
}

.modal-upload-drag-certificate-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #90b8d3;
}

.modal-upload-drag-certificate-desc {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #90b8d3;
}

.list-file-uploaded-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  box-shadow: 0px 0px 15px rgba(45, 73, 88, 0.15);
  padding: 16px 18px;
  border-radius: 10px;
}

.list-file-uploaded-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #132c56;
}
