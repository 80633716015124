@media(min-width:1224px){
    .patient-page-title{
        font-size: 1.6rem;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .patient-page-subtitle{
        text-align: left;
        font-size: 1.3rem;
        text-align: left;
    }
    
    .patient-buttons .back-button{
        font-size: 14px !important;
        margin-left: 2rem !important;
        margin-top: -2rem !important;
    }
    
    .patient-buttons button{
        float: right;
        width: 8rem;
        font-size: 14px !important;
    }
}

@media(min-width: 1879px) and (min-width: 800px){
    

.btn-add-schedule{
    float: right;
    font-size: 14px;
    margin-top: -4rem;
    width: 10rem;
    background-color: #00BF16;
}

.vaccine-delete, .vaccine-change{
    width: 5rem;
    height: 2rem;
    font-size: 14px;
}
.vaccine-delete{
    margin-right: 1rem;
}
.vaccine-types-buttons{
    float: right;
    margin-right: 10rem;
}

.search-content{
    margin-left: 89.5rem;
    width: 15rem;
}

.showing-button{
    margin-left: 1rem;
}

#pageDropDown{
    margin-left: 5rem;
    background-color: #007BFF;
    border: #007BFF;
}
.patient-content .css-26l3qy-menu{
    width: 30rem !important;
    margin-left: 0rem !important;
    margin-top: 1.5rem  !important;
}

.patient-buttons .back-button{
    margin-left: 2rem !important;
    margin-top:-2rem !important;
}

.patient-buttons button{
    float: right;
    width: 8rem;
}
}