@media (min-width:1224px) {
    .horizontal-line{
        width: 100% !important;
        opacity: 0.5;
    }
    
    .input-detail-queue .css-26l3qy-menu{
        width: 20rem !important;
        margin-left: 0rem !important;
        margin-top: 0rem !important;
        font-size: 14px;
    }
    .detail-queue{
        font-size: 1rem;
    }
    .input-detail-queue button{
        width: 7rem;
        height: 2rem;
        font-size: 12px;
        margin-top: 1rem;
    }
    
    .input-detail-queue .save-detail{
        margin-left: 9.5rem;
    }
    
    .input-detail-queue  .css-yk16xz-control,
    .input-detail-queue .css-2b097c-container{
        width: 20rem;
        font-size: 14px;
    }
    }
    

@media(min-width: 1879px) and (min-width: 800px){
.horizontal-line{
    width: 100% !important;
    opacity: 0.5;
}

.input-detail-queue .css-26l3qy-menu{
    width: 20rem !important;
    margin-left: 0rem !important;
    margin-top: -5rem ;
}

.input-detail-queue button{
    width: 7rem;
    height: 2rem;
    font-size: 12px;
    margin-top: 3rem;
}

.input-detail-queue .save-detail{
    margin-left: 9.5rem;
}
.input-detail-queue  .css-yk16xz-control,
.input-detail-queue .css-2b097c-container{
    width: 20rem;
}
}

.container-wrapper-receipt {
    position: relative;
    max-width: 10em;
}

.image-payment {
    width: 10em;
    height: auto;
    border: 1px solid #00000038;
    cursor: pointer;
    opacity: 1;
    transition: .5s ease;
}

.middle-receipt {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container-wrapper-receipt:hover .image-payment {
    border: 1px solid #039beb;
    opacity: 0.75;
}

.container-wrapper-receipt:hover .middle-receipt {
    opacity: 1
}

.confirm-payment-radio {
    padding: .5em 2em .5em 3em
}

.confirm-payment-radio input:checked {
    color: green
}

.form-payment-confirm {
    padding: 1em 12em;
}

.choice-refused-payment {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s, overflow 0s;
}

.show-choice-refused {
  max-height: 20em;
  overflow:auto;
  transition: max-height 0.5s, overflow 0.5s 0.5s;
}

.react-datepicker-wrapper .date-picker-custom {
    margin: 0;
}
