.container-scanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-token {
  position: absolute;
  top: -1000px;
}

.scanner-img-wrapper {
  width: 323px;
  height: 323px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 50px;
}

.scanner-title {
  font-weight: 800;
  font-size: 60px;
  line-height: 73px;
  text-align: center;
  color: #ffffff;
}
.scanner-sub-title {
  max-width: 1042px;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
}

.loading-self-checkin-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000005d;
}

.loading-self-checkin-content {
  background: white;
  padding: 40px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
