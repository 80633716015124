.note-body{
    height: 100vh;
    background-color: white;
    padding: 2rem;
}
.note-body .note-input{
    width: 100%;
    height: 10rem;
    border: 1.3px solid rgb(36, 177, 253);
    margin-bottom: 1.3rem;
    border-radius: 5px;
    padding-left: 1rem;
    line-height: 3rem;
}

.note-input::-webkit-input-placeholder{
    color: #7B7B7B;
    font-size: 11px;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: left;
    /* margin-top: -5rem; */
    /* vertical-align: text-top; */
    /* text-align: 2rem; */
    margin-top: 1rem;
    line-height: 3rem;
}

.note-body button{
    width: 100%;
    padding: 0.7rem;
    background-color: #039BEB;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
}