
.login-admin-body{
    background-color: white;
    height: 130vh;
    width: 100%;
    padding-top: 2rem;
}
.login-image{
    width: 0rem;
    margin: auto;
}

.bob-style{
    width: 10rem;
    margin-left: 20%;
    display: inline-block;
}

.login-title{
    font-size: 2rem;
    font-weight: bold;
    color: black;
    display: block;
    line-height: 1;
    text-align: left;
    width:10rem;
    margin-left:13rem;
    margin-top:-5rem;
}
.adm-login-container{
    margin-top: -7rem;
    margin-left: 3rem;
}

.login-form .login-text{
    text-align: left;
    margin-top: 0rem;
    margin-left: 0%;
    font-weight: bold;
    font-size: 1.4rem;
    color: #585858;
}
.title-style{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.admin-input{
    width: 100%;
    background-color: #EFEFEF !important;
    border: 1px solid #EFEFEF !important;
    height: 2.5rem;
    margin-bottom: 1rem;
}
.admin-input::-webkit-input-placeholder{
    font-size: 12px;
    color: #A2A2A2;
    padding: 5px;
    font-family: 'Roboto', sans-serif;
}
.adm-login-button{
    width: 100%;
    margin-left: 0rem;
    margin-top: 2rem;
    background-color: #039BEB;
    height: 2.5rem;
    margin-bottom: 10rem;
}
.valid-feedback,.invalid-feedback{
    margin-top: -0.5rem;
    text-align: left;
    width: 10rem;
}
.login-form{
   margin: auto;
   margin-top: 5rem;
   width: 80% !important;
}
.adm-login-container .login-text{
    text-align: left;
    margin-top: -5rem;
    margin-left: 0rem;
}

@media (min-width:500px){
    .bob-style{
        width: 30% !important;
        margin-top: 10%;
        margin-left: 13rem;
    }
    .login-title{
        margin-top: -5rem;
        font-size: 3rem;
        font-weight: bold;
        color: black;
        margin-left: 23rem;
        display: block;
        width: 10%;
    }
    .login-form{
        margin: auto;
        width: 80% !important;
     }

     .title-wrapper{
         margin-left: 2rem;
     }
     .login-form .admin-input{
         width: 100%;
         background-color: #EFEFEF;
         border: 1px solid #EFEFEF;
         height: 3rem;
         margin-bottom: 1rem;
     }
     .admin-input::-webkit-input-placeholder{
        font-size: 1rem;
        color: #A2A2A2;
        padding: 5px;
        font-family: 'Roboto', sans-serif;
    }
    .adm-login-button{
        width: 100%;
        margin-left: 0rem;
        margin-top: 2rem;
        background-color: #039BEB;
        height: 3rem;
        margin-bottom: 10rem;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 1.2rem;
        font-family: 'Roboto', sans-serif;
    }
    .login-form .login-text{
        text-align: left;
        margin-top: 0rem;
        margin-left: 0%;
        font-weight: bold;
        font-size: 1.4rem;
        color: #585858;
    }
}

@media(min-width:1000px){
    .bob-style{
        width: 15rem !important;
        margin-top: 10%;
        margin-left: 20rem;
    }
    .login-title{
        margin-top: -7rem;
        font-size: 3rem;
        font-weight: bold;
        color: black;
        margin-left: 33rem;
        display: block;
        width: 10%;
    }
    .login-form{
        margin: auto;
        margin-top: 5rem;
        width: 80% !important;
     }

     .login-form .admin-input{
         width: 100%;
         background-color: #EFEFEF;
         border: 1px solid #EFEFEF;
         height: 5rem;
         margin-bottom: 1rem;
     }
     .admin-input::-webkit-input-placeholder{
        font-size: 1.5rem;
        color: #A2A2A2;
        padding: 5px;
        font-family: 'Roboto', sans-serif;
    }
    .adm-login-button{
        width: 100%;
        margin-left: 0rem;
        margin-top: 2rem;
        background-color: #039BEB;
        height: 5rem;
        margin-bottom: 10rem;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 1.5rem !important;
        font-family: 'Roboto', sans-serif;
    }
    .login-form .login-text{
        text-align: left;
        margin-top: 0rem;
        margin-left: 0%;
        font-weight: bold;
        font-size: 2rem;
        color: #585858;
    }
}
@media (min-width:1279px){
    .login-admin-body{
        padding-top: 8rem !important;
        padding-left: 20rem !important;
        background-color: white;
        width: 100% !important;
        height: 130vh !important;
    }
    .img-container{
        float: left;
    }
    
    .login-image{
        width: 65% !important;
        margin: -4% 0 10% -20% !important;
    }
    
    .adm-login-container .login-text{
        font-size: 1.7rem !important;
        text-align: left;
        margin-top: 5rem !important;
        margin-left: 0rem !important;
        font-weight: bold;
        color: #585858;
    }
    .login-title{
        font-size: 1.8rem !important;
        color: black;
        font-weight: bold;
        line-height: 1;
        margin-top: -3rem;
        margin-left: 1rem;
    }
    .login-form{
       width: 40% !important;
       margin-top: -40% !important;
       margin-left: 50% !important;
    }
    
    .admin-input{
        width: 100% !important;
        background-color: #EFEFEF;
        border: 1px solid #EFEFEF;
        height: 3rem !important;
        margin-bottom: 1.5rem;
        font-size: 1rem;
    }
    
    .admin-input::-webkit-input-placeholder{
        font-size: 1rem;
        color: #A2A2A2;
        padding: 5px;
        font-family: 'Roboto', sans-serif;
    }
    
    .adm-login-button{
        width: 100% !important;
        font-size: 1rem !important;
        font-weight: 500;
        background-color: #039BEB;
        height: 3rem !important;
        letter-spacing: 5px !important;

    }
    
    
    .valid-feedback,.invalid-feedback{
        text-align: left;
        margin-top: -1rem;
        width: 30rem !important;
        font-size: 1.1rem;
    }
    .bob-style{
        width: 6rem !important;
        display: inline-block;
       margin: -10% 0 0 -50%;
    }
    .title-wrapper{
        margin-bottom:-2rem !important;
        text-align: left !important;
        position: absolute !important;
        left: 63% !important;
        top:19% !important;
    }

    
}

@media(min-width: 1879px){
    .login-admin-body{
        padding-top: 8rem;
        padding-left: 20rem;
        background-color: white;
        width: 100% !important;
        height: 110vh !important;
    }

    .title-wrapper{
        top:15% !important;
        left:56% !important;
    }
    .login-image{
        width: 50rem !important;
        margin-left:-7% !important;
        margin-top: -3rem !important;
    }
    .bob-style{
        width: 7rem !important;
        margin-top: 0rem !important;
        margin-left: 0rem;
    }
     .login-title{
        font-size: 2rem !important;
        color: black;
        font-weight: bold;
        margin-top: -3rem !important;
        margin-left: 6rem !important;
    }
    .adm-login-container .login-text{
        font-size: 1.5rem !important;
        text-align: left;
        margin-top: 5rem;
        margin-left: 0rem;
        font-weight: bold;
        color: #585858;
    }
    .login-form{
        margin-left: 47% !important;
        margin-top: -33% !important;
    }
    .admin-input{
        width: 30rem;
        background-color: #EFEFEF;
        border: 1px solid #EFEFEF;
        height: 3rem;
        margin-bottom: 1.5rem;
    }
    
    .admin-input::-webkit-input-placeholder{
        font-size: 1rem;
        color: #A2A2A2;
        padding: 5px;
        font-family: 'Roboto', sans-serif;
    }
    .adm-login-button{
        width: 100% !important;
        font-size: 1.3rem !important;
        font-weight: 500;
        background-color: #039BEB;
        height: 3rem !important;
        letter-spacing: 5px !important;
    }
    .valid-feedback,.invalid-feedback{
        font-size: 1rem;
    }
  }