.container-success-checkin {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.wrapper-success-checkin {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 48px 64px;
  border-radius: 20px;
  width: 579px;
  max-height: 90%;
}

.top-content-success-checkin {
  height: 100%;
  overflow-y: auto;
}

.top-content-success-checkin::-webkit-scrollbar {
  display: none;
}

.img-wrapper-success-checkin {
  margin-bottom: 24px;
}

.title-success-checkin {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #232323;
  margin: 0;
  margin-bottom: 58px;
}

.sub-title-success-checkin {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  color: rgba(35, 35, 35, 0.7);
  margin-bottom: 24px;
}

.wrapper-queue-success-checkin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  /* margin-top: 58px; */
}

.queue-number-success-checkin {
  font-size: 60px;
  line-height: 73px;
  text-align: center;
  color: #232323;
  margin: 0;
}

.sub-title-queue-success-checkin {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  color: rgba(35, 35, 35, 0.7);
}

.button-success-checkin {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: none;
  background: #f06b10;
}

.button-success-checkin:hover {
  background: #f06a109c;
}
