.queue-table {
  margin-left: 1rem;
}

.queue-table .row {
  width: 55% !important;
  position: absolute;
  top: 85% !important;
}
.queue-table .table{
  font-size: 1.2rem;
}

.queue-table .pagination {
  margin-left: 50%;
}

.queue-table-title {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: -5rem;
}

.card-queue {
  width: 40% !important;
  margin-left: 5rem;
}

.card-queue .card-header {
  background-color: #c1e4f7;
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  font-family: arial;
  padding: 1rem;
}

.card-queue2 .card-header {
  background-color: #e6eaeb !important;
}

.card-queue2 .card-text {
  background-color: #f3f4f6 !important;
}

.card-queue .card-number {
  font-size: 24vh;
  color: black;
  margin-top: -2rem;
  margin-bottom: -4rem;
  font-weight: bold;
}

.card-queue .card-text {
  background-color: #e5f6fe;
  height: 33vh;
}

.card-queue .card-patient {
  font-size: 2em;
  color: black;
  margin-bottom: 0rem;
  font-weight: bold;
}

/* EMERGENCY */

.emergency-queue {
  margin-left: -7rem;
  margin-top: -1rem !important;
  position: absolute;
}
.emergency-queue2 {
    margin-left: 3rem;
    margin-top: -1rem !important;
    position: absolute;
  }

@media(min-width:1879px){
    .queue-table .pagination {
        margin-left: 45%;
      }
      
}

.show-card-queue .card-body{
  height: 100vh;
}