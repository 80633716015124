.container-running-queue {
  height: 100vh;
  overflow: hidden;
  background-color: #039bea;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.content-wrapper {
  flex: 1;
  background: white;
  border-radius: 16px;
  padding: 2rem;
}

.divider {
  border-bottom: 1px solid gainsboro;
}

.content-title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.form-input {
  position: relative;
  width: 60%;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input {
  width: 100%;
  height: 50px;
  padding: 10px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
}

.form-input label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
  transition: 0.3s ease-in-out all;
}

.form-input input:focus + label,
.form-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.8rem;
  color: black;
  background: #fff;
  padding: 7px;
}

.form-input input:focus {
  border: 2px solid #039bea !important;
}

.form-input input:focus + label {
  color: #039bea !important;
}
