

.add-shift-table .css-26l3qy-menu{
    width: 30rem !important;
    margin-left: 0.2rem !important;
    margin-top: 0rem !important;
    background-color: white;
}

.add-shift-table .css-2b097c-container{
    margin-top: -2rem !important;
}