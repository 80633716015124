@media(min-width:1224px){

    .patient-page-title{
        font-size: 1.4rem;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .patient-page-subtitle{
        text-align: left;
        font-size: 1.1rem;
        text-align: left;
    }

    .patient-page-title{
        font-size: 1.4rem;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .patient-page-subtitle{
        text-align: left;
        font-size: 1.1rem;
        text-align: left;
    }

    .add-table .css-26l3qy-menu{
        width: 30rem !important;
        margin-left: 0.3rem !important;
        background-color: white;
        margin-top: 0rem !important;
    }

    .add-table .css-2b097c-container{
        margin-left: 1rem !important;
    }


    .add-table .react-datepicker__input-container{
        margin-left: 0rem !important;
    }
    .address-styling{
        float: right;
    }

}

@media(min-width: 1879px){
    .add-table .css-26l3qy-menu{
        width: 30rem;
        margin-left: 5rem;
        margin-top: -1rem;
    }

    .address-styling{
        float: right;
    }

}