@media (min-width:1224px) {
    .add-vaccine-input{
        margin-left: 1rem;
        width: 30rem;
        height: 2.3rem;
        border: 1px solid black !important;
        border-radius: 3px;
        padding: 5px;
        font-size: 14px;
    }
    
    .patient-content-buttons{
        margin-top: 3rem;
    }
    
    .patient-content-buttons button{
        width: 7rem;
    }
}
@media(min-width: 1879px) and (min-width: 800px){
.add-vaccine-input{
    margin-left: 1rem;
    width: 30rem;
    height: 3rem;
    border: 1px solid black !important;
    border-radius: 3px;
    padding: 1rem;
    font-size: 1rem;
}

.patient-content-buttons{
    margin-top: 3rem;
}

.patient-content-buttons button{
    width: 7rem;
}
}