@media (min-width: 700px) {
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.close-section .close-icon{
  font-size: 3rem;
  float: right;
  margin-top:-5rem;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  display: none;
}

.open-icon{
  color: #039beb;
  float: left;
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-top: 0.8rem;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  /* display: none !important; */
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 20rem !important;
  margin-top: -3rem !important;
}

/* General sidebar styles */
.bm-menu {
  background: #039beb;
  padding: 2.5em 1.5em 0;
  font-size: 12px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  margin-bottom: 1rem;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
  display: none;
}

.adm-sidebar-title {
  text-align: left;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 5px;
  font-weight: bold;
}
.dropdown-sidebar {
  color: white;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  padding-left: 1rem;
}
.dropdown-custom {
  color: white !important;
  font-size: 14px !important;
}

.dropdown-sidebar:hover {
  color: white;
  text-decoration: none;
  background-color: #0b69a1;
  height: 2.5rem;
  line-height: 2.5rem;
}

#contact {
  color: white;
}

.dropdown-sidebar2 {
  color: white !important;
  font-size: 1rem;
}
.dropdown-sidebar2:hover {
  color: white !important;
  text-decoration: none;
  background-color: #0b69a1;
}
.dropdown-custom {
  color: white !important;
  font-size: 1rem !important;
}
.nav-link[data-toggle].collapsed:after {
  content: "▾";
  margin-left: 1em;
  color: white;
}
.nav-link[data-toggle]:not(.collapsed):after {
  content: "▴";
  margin-left: 1em;
  color: white;
}

/* navbar section */
.adm-navbar {
  width: 100%;
  height: 3rem;
  background-color: white;
  padding-right: 7rem;
  /* position:absolute; */
}

.admin-section {
  margin-left: 0rem;
}

.user-icon {
  width: 2rem;
  margin-left: 5px;
  margin-top: -5px;
}

#dropdown-basic-button {
  background: transparent;
  border: none !important;
  color: black;
  width: 2rem;
  padding-top: 10px;
}

#dropdown-basic-button.show {
  background: transparent;
  border: none !important;
  color: white;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
}

.admin-name {
  font-size: 1.1rem !important;
  font-family: "Roboto";
  color: #039beb;
  margin-top: 5px;
}

.adm-navbar .dropdown-toggle::after {
  display: none;
}

.adm-navbar .dropdown-menu.show {
  left: 4rem !important;
}
}

/* DEFAULT SIDEBAR STYLE */


@media(min-width:1200px){
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.open-icon{
  display: none;
}


/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  /* display: none !important; */
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.close-section{
  font-size: 0rem !important;
  display: none !important;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 20rem !important;
  margin-top: -3rem !important;
}

/* General sidebar styles */
.bm-menu {
  background: #039beb;
  padding: 2.5em 1.5em 0;
  font-size: 12px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  margin-bottom: 1rem;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
  display: none;
}

.adm-sidebar-title {
  text-align: left;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 5px;
  font-weight: bold;
}
.dropdown-sidebar {
  color: white;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  padding-left: 1rem;
}
.dropdown-custom {
  color: white !important;
  font-size: 14px !important;
}

.dropdown-sidebar:hover {
  color: white;
  text-decoration: none;
  background-color: #0b69a1;
  height: 2.5rem;
  line-height: 2.5rem;
}

#contact {
  color: white;
}

.dropdown-sidebar2 {
  color: white !important;
  font-size: 1rem;
}
.dropdown-sidebar2:hover {
  color: white !important;
  text-decoration: none;
  background-color: #0b69a1;
}
.dropdown-custom {
  color: white !important;
  font-size: 1rem !important;
}
.nav-link[data-toggle].collapsed:after {
  content: "▾";
  margin-left: 1em;
  color: white;
}
.nav-link[data-toggle]:not(.collapsed):after {
  content: "▴";
  margin-left: 1em;
  color: white;
}

/* navbar section */
.adm-navbar {
  width: 100%;
  height: 3rem;
  background-color: white;
  padding-right: 7rem;
  /* position:absolute; */
}

.admin-section {
  margin-left: 0rem;
}

.user-icon {
  width: 2rem;
  margin-left: 5px;
  margin-top: -5px;
}

#dropdown-basic-button {
  background: transparent;
  border: none !important;
  color: black;
  width: 2rem;
  padding-top: 10px;
}

#dropdown-basic-button.show {
  background: transparent;
  border: none !important;
  color: white;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
}

.admin-name {
  font-size: 1.1rem !important;
  font-family: "Roboto";
  color: #039beb;
  margin-top: 5px;
}

.adm-navbar .dropdown-toggle::after {
  display: none;
}

.adm-navbar .dropdown-menu.show {
  left: 4rem !important;
}
}