
@media(min-width:700px){
    .queue-body {
        width: 74%;
        background-color: white;
        margin: 5rem 0 0 -1rem;
        padding: 2rem 2rem 7rem 2rem;
        margin-bottom: 10rem;
      }
      .history-buttons button {
        font-size: 12px !important;
      }
    
      .queue-container {
        width: 73%;
        height: 20rem;
        background-color: #e6f6ff;
        margin: auto;
        padding: 1rem;
        float: left;
        margin-top: 2rem;
      }
      
      .queue-container-consult {
        width: 73%;
        height: 20rem;
        background-color: #DFF4E7;
        margin: auto;
        padding: 1rem;
        float: left;
        margin-top: 2rem;
      }
    
      .queue-container2 {
        width: 25%;
        background-color: #00000029;
        padding: 3rem;
        margin-top: 2rem;
        float: right;
        height: 20rem;
      }
    
      .queue2-title {
        font-size: 1.3rem;
        color: #464545;
      }
    
      .queue2-number {
        font-size: 7rem;
        margin-top: -2rem;
        color: #464545;
      }
      .queue-body-title {
        font-size: 1rem;
        text-align: left;
        margin-left: 0.5rem;
      }
    
      .queue2-body-title {
        font-size: 1rem;
        text-align: left;
        margin-left: 0.5rem;
        margin-top: 25rem;
      }
    
      .queue-body .row {
        text-align: left;
        margin-bottom: 3rem;
      }
    
      .no-order {
        font-size: 1rem;
        margin-top: 5rem;
        color: red;
      }
    
      .queue-title {
        font-size: 2rem;
        text-align: center;
      }
    
      .number-title,
      .number-subtitle {
        display: inline !important;
      }
    
      .queue-information {
        font-family: "Roboto";
        font-size: 14px;
      }
      .queue-body .pagination {
        float: right !important;
      }
      .end-shift-button {
        width: 10rem !important;
        margin-right: 1rem;
        float: right;
        font-size: 14px;
      }
      .number-container {
        margin-top: -2rem;
      }
      .emergency-icon {
        width: 14px;
        margin-top: -5px;
        margin-left: -3px;
      }
    
      .emergency-text {
        margin-left: 3px;
        color: red;
      }
      .btn-emergency {
        width: 5rem;
        border: 1px solid red;
        background: transparent;
        margin-left: 1rem;
      }
      .emergency-image {
        position: absolute !important;
        margin-top: -1rem;
        margin-left: -20rem;
      }
      .emergency-image2 {
        position: absolute !important;
        margin-top: -1rem;
        margin-left: 15rem;
      }
      .order-emergency {
        width: 2rem;
        position: absolute;
        margin-top: -1%;
        margin-left: -3%;
      }
      .add-queue-button {
        width: 10rem;
      }
    
      .buttons-queue {
        width: 25rem;
        float: right;
        display: grid;
        grid-template-columns: repeat(2, 2fr);
      }
    
      .queue_patient_name {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: black;
      }
      .next-queue-patient-name {
        font-size: 1.3rem;
        margin-top: -9rem;
        color: black;
      } 
}
@media (min-width: 1224px) {
  .queue-body {
    width: 74%;
    background-color: white;
    margin: 5rem 0 0 -1rem;
    padding: 2rem 2rem 7rem 2rem;
    margin-bottom: 10rem;
  }
  .history-buttons button {
    font-size: 12px !important;
  }

  .queue-container {
    width: 73%;
    height: 20rem;
    background-color: #e6f6ff;
    margin: auto;
    padding: 1rem;
    float: left;
    margin-top: 2rem;
  }

  .queue-container-consult {
    width: 73%;
    height: 20rem;
    background-color: #DFF4E7;
    margin: auto;
    padding: 1rem;
    float: left;
    margin-top: 2rem;
  }

  .queue-container2 {
    width: 25%;
    background-color: #00000029;
    padding: 3rem;
    margin-top: 2rem;
    float: right;
    height: 20rem;
  }

  .queue2-title {
    font-size: 1.3rem;
    color: #464545;
  }

  .queue2-number {
    font-size: 7rem;
    margin-top: -2rem;
    color: #464545;
  }
  .queue-body-title {
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
  }

  .queue2-body-title {
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
    margin-top: 25rem;
  }

  .queue-body .row {
    text-align: left;
    margin-bottom: 3rem;
  }

  .no-order {
    font-size: 1rem;
    margin-top: 5rem;
    color: red;
  }

  .queue-title {
    font-size: 2rem;
    text-align: center;
  }

  .number-title,
  .number-subtitle {
    display: inline !important;
  }

  .queue-information {
    font-family: "Roboto";
    font-size: 14px;
  }
  .queue-body .pagination {
    float: right !important;
  }
  .end-shift-button {
    width: 10rem !important;
    margin-right: 1rem;
    float: right;
    font-size: 14px;
  }
  .number-container {
    margin-top: -2rem;
  }
  .emergency-icon {
    width: 14px;
    margin-top: -5px;
    margin-left: -3px;
  }

  .emergency-text {
    margin-left: 3px;
    color: red;
  }
  .btn-emergency {
    width: 5rem;
    border: 1px solid red;
    background: transparent;
    margin-left: 1rem;
  }
  .emergency-image {
    position: absolute !important;
    margin-top: -1rem;
    margin-left: -20rem;
  }
  .emergency-image2 {
    position: absolute !important;
    margin-top: -1rem;
    margin-left: 15rem;
  }
  .order-emergency {
    width: 2rem;
    position: absolute;
    margin-top: -1%;
    margin-left: -3%;
  }
  .add-queue-button {
    width: 10rem;
  }

  .buttons-queue {
    width: 25rem;
    float: right;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  .queue_patient_name {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: black;
  }
  .next-queue-patient-name {
    font-size: 1.3rem;
    margin-top: -9rem;
    color: black;
  }
}

@media (min-width: 1879px) {
  .history-buttons button {
    font-size: 12px !important;
  }

  .queue-body {
    width: 79% !important;
    background-color: white;
    margin: 4rem 2rem 0rem 4rem !important;
    padding: 0rem 2rem 0.5rem 2rem !important;
    margin-top: 1rem;
    margin-bottom: 10rem;
  }

  .queue-container {
    width: 73%;
    background-color: #e6f6ff;
    margin: auto;
    padding: 3rem;
    float: left;
    margin-top: 2rem;
    height: 25rem;
  }

  .queue-container-consult {
    width: 73%;
    background-color: #DFF4E7;
    margin: auto;
    padding: 3rem;
    float: left;
    margin-top: 2rem;
    height: 25rem;
  }

  .queue-container2 {
    width: 25%;
    background-color: #00000029;
    padding: 3rem;
    margin-top: 2rem;
    float: right;
    height: 25rem;
  }
  .queue_patient_name {
    font-size: 1.5rem;
    margin-bottom: 0;
    color: black;
  }
  .queue2-title {
    font-size: 2rem;
    color: #464545;
  }

  .queue2-number {
    font-size: 8rem;
    color: #464545;
  }
  .queue-body-title {
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
  }

  .number-container {
    margin-top: -2rem;
  }

  .queue2-body-title {
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
    margin-top: 27rem;
    margin-bottom: -3rem;
  }

  .add-queue-button {
    width: 10rem;
  }

  .buttons-queue {
    width: 25rem;
    float: right;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  .queue-body .row {
    text-align: left;
  }

  .no-order {
    font-size: 1rem;
    margin-top: 5rem;
    color: red;
  }

  .queue-title {
    font-size: 2rem;
    text-align: center;
  }

  .number-title,
  .number-subtitle {
    display: inline !important;
  }

  .queue-information {
    font-family: "Roboto";
    font-size: 14px;
  }

  .end-shift-button {
    width: 13rem !important;
    margin-right: -0.5rem;
    float: right;
    font-size: 1rem;
  }
  .btn-emergency {
    width: 5rem;
    border: 1px solid red;
    background: transparent;
    margin-left: 1rem;
  }
  .emergency-icon {
    width: 14px;
    margin-top: -5px;
    margin-left: -3px;
  }

  .emergency-text {
    margin-left: 3px;
    color: red;
  }
  .emergency-image {
    position: absolute !important;
    margin-top: -3rem !important;
  }
  .emergency-image2 {
    position: absolute !important;
    margin-top: -3rem !important;
  }
  .order-emergency {
    width: 2rem;
    position: absolute;
    margin-top: -0.7%;
    margin-left: -3%;
  }
  .next-queue-patient-name {
    font-size: 1.5rem;
    margin-top: -9rem;
    color: black;
  }
}
