

.css-26l3qy-menu{
    width: 30rem !important;
    margin-left: 9rem;
}

.edit-vaccine-input{
    margin-left: 1rem;
    width: 15rem;
    height: 2.3rem;
    border: 1px solid black !important;
    border-radius: 3px;
    padding: 1rem;
}