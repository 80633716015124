@media (min-width:1224px) {
    .warning-text{
        color:red;
        font-weight: normal;
    }
    
    .btn-checkup{
        float:right;
        margin-bottom: 1rem;
        font-size:14px !important ;
    }
    .image-container .patient-photo{
        margin-top: -15rem !important;
        width:10rem !important;
        float:right !important;
    }
    
.no-photo{
    width: 10rem;
    height:10rem;
    background-color: #00000029;
    float:right !important;
    margin-top: -16rem !important;
    text-align: center;
}

.no-photo-text{
    line-height:10rem;
    font-family: 'Roboto';
    font-weight:500;
    font-size: 14px;
}

.checkup-table .row{
    margin-left: 3rem !important;

}
}
@media(min-width: 1879px) and (min-width: 800px){
.warning-text{
    color:red;
    font-weight: normal;
}

.btn-checkup{
    float:right;
    margin-bottom: 1rem;
}

.image-container .patient-photo{
    margin-top: -16rem !important;
    width:10rem !important;
    float:right !important;
}

.no-photo{
    width: 10rem;
    height:10rem;
    background-color: #00000029;
    float:right !important;
    margin-top: -16rem !important;
    text-align: center;
}

.no-photo-text{
    line-height:10rem;
    font-family: 'Roboto';
    font-weight:500;
}
}