.vaccination-page-title{
    font-size: 1.4rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.vaccination-page-subtitle{
    text-align: left;
    font-size: 1.1rem;
    text-align: left;
}


.vaccination-schedule-title{
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Roboto';
    margin-bottom: -2.5rem;
}

.schedule-button{
    width: 10rem;
    font-size: 12px;
}

.react-bootstrap-table{
    margin-bottom: 5rem !important;
}

.schedule-content{
    background-color: white !important;
    text-align: left;
    width:92%;
    margin:-3rem auto;
    padding: 3rem 3rem 3rem 3rem;
    margin-bottom: 15rem;
}