.container-template {
  width: 100%;
  height: 100%;
}

.body-print {
  background-color: #0fa8f9;
}

.certificate-wrapper {
  background: #ffffff;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.certificate-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2f638e;
}

.certificate-sub-title {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2f638e;
  margin-top: 10px;
  margin-bottom: 34px;
}

.certificate-recipient-wrapper {
  margin-top: 20px;
  padding: 14px 26px;
  width: max-content;
  background: linear-gradient(
    85.81deg,
    #3294ee -44.38%,
    #0fa8f9 52.86%,
    #45a6ff 120.8%
  );
  border-radius: 12px;
  margin-bottom: 34px;
}

.certificate-name {
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: white;
}

.certificate-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.28);
  width: 320px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.certificate-info {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.certificate-info-item {
  width: 100%;
}

.certificate-info-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.certificate-info-sub-title {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #ffffff;
  word-wrap: break-word;
  word-break: break-all;
  margin-top: 8px;
}

.table-template-certificate-wrapper .ant-table-container,
.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table {
  border: none !important;
}

.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table
  > thead.ant-table-thead
  > tr
  > th.ant-table-cell {
  font-size: 12px !important;
  padding: 0px !important;
  text-align: center !important;
  border: 1px solid rgb(0, 0, 0) !important;
}

.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table
  > tbody.ant-table-tbody
  > tr {
  border: 1px solid rgb(0, 0, 0) !important;
}

.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table
  > tbody.ant-table-tbody
  > tr
  > td.ant-table-cell {
  padding: 4px !important;
  text-align: left !important;
  font-size: 11px !important;
  border: 1px solid rgb(0, 0, 0) !important;
}

.table-template-certificate-wrapper table {
  width: 100%;
}

.table-template-certificate-wrapper table th {
  text-align: center;
  font-weight: 500;
  background: #f2f2f2;
  font-size: 11px;
}
.table-template-certificate-wrapper table,
.table-template-certificate-wrapper table th,
.table-template-certificate-wrapper table td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px 6px;
  font-size: 11px;
}

@page {
  size: legal;
  margin: 1rem;
}
@media print {
  .container-template {
    height: 100vh;
  }
  .signing-wrapper {
    break-inside: avoid;
  }
}

/* 
.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table
  > thead.ant-table-thead
  > tr
  > th.ant-table-cell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: center !important;
}
.table-template-certificate-wrapper
  .ant-table-container
  > .ant-table-content
  > table
  > tbody.ant-table-tbody
  > tr
  > td.ant-table-cell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: left !important;
}

table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

@media print {
  @page {
    size: A4 landscape;
    margin: 16px;
  }

  div.page-footer,
  div.footer-info {
    display: block;
  }

  .container-template {
    height: 100vh;
  }
  .signing-wrapper {
    break-inside: avoid;
  }
} */
