body {
  background-color: #f4f4f4;
}
.MuiTabs-flexContainer {
  background-color: #039beb;
}

.MuiTab-wrapper {
  color: white !important;
}

.checkup-navbar {
  width: 100%;
  height: 3rem !important;
  background-color: #039beb;
  border: 1px solid #039beb;
  padding: 2rem;
}

.checkup-navbar-title {
  color: white;
  float: left;
  font-size: 1rem !important;
  margin-top: -0.5rem !important;
  margin-left: -1.5rem !important;
  font-family: "Capriola", sans-serif;
}

.makeStyles-root-1 {
  width: 100% !important;
}

.jss1 {
  width: 100% !important;
}

/* TAB ONE - CHECKUP */
.tab-body {
  background-color: #f4f4f4;
  height: 110vh;
}

.tab-card {
  width: 100%;
  height: 6rem;
  background-color: white;
  margin: auto;
  padding: 1rem;
}
.profile-body {
  margin-left: 5rem;
  margin-top: 1rem;
  line-height: 10px;
}

.checkup-date {
  text-align: left;
  font-size: 12px;
  margin-top: -5px;
}
.checkup-name {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: left;
}
.checkup-picture {
  height: 4em;
  width: auto;
  float: left;
}
.checkup-number,
.queue-number,
.skip-number,
.completed-number,
.emergency-number {
  width: 2rem;
  height: 2rem;
  float: right;
  margin-left: 8rem;
  margin-top: -2rem;
  border-radius: 10%;
}

.emergency-number {
  background-color: #ce2828;
}

.nurse-emergency-icon {
  position: absolute;
  margin-left: 4px;
  margin-top: 5px;
}
.checkup-number,
.completed-number {
  background-color: #00bf16;
}
.queue-number {
  background-color: #039beb;
}

.skip-number {
  background-color: #ce2828;
}
.queue-text {
  color: white;
  font-weight: bold;
  text-align: auto;
  line-height: 2rem;
  font-size: 1rem;
}

.anchor-card,
.anchor-card:active,
.anchor-card:hover {
  color: black;
  text-decoration: none;
}

.menu-bars {
  width: 1.4rem;
  float: right;
}

.checkup-navbar .dropdown-menu {
  top: -1.8rem !important;
  left: -0.5rem !important;
}

.tab-card {
  margin-bottom: 1rem !important;
}

.checkup-name-box {
  width: 80%;
  line-height: 1.5rem;
  margin-top: -1.2rem;
}
.MuiTypography-body1 {
  height: 100% !important;
}

#full-width-tabpanel-4 {
  background-color: white;
  height: 100vh;
}

.MuiTab-wrapper {
  font-size: 12px;
  font-family: "Capriola", sans-serif;
}

#full-width-tabpanel-4 .MuiBox-root-111 {
  padding: 1rem 10px 0 10px !important;
  height: 100vh !important;
}

/* NURSE TABLE BODY */

.nurse-table-body {
  background-color: white;
}
.nurse-table {
  font-size: 12px;
  width: 100%;
  background-color: white;
}

.nurse-table-icon {
  font-size: 12px;
  margin-top: 5px;
}

.nurse-table-btn {
  width: 25px;
  height: 25px;
  border: 0px;
  border-radius: 3px;
}

.nurse-table .search-label {
  float: right;
}

.nurse-table .search-content {
  font-size: 10px;
  width: 8rem;
}

.nurse-table .pagination {
  float: right;
  margin-right: 1rem !important;
}

.nurse-table .react-bootstrap-table {
  margin-bottom: 0 !important;
  margin-left: 0rem !important;
}

.nurse-table-body .table-information {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 2px;
}

.nurse-btn-formatter {
  border: 0;
  padding: 5px;
  width: 4.5rem;
  border-radius: 5px;
  font-size: 11px;
}

.checkup-overlay.css-42igfv {
  margin-top: 80% !important;
}

.checkup-overlay .css-df17o1 {
  height: 100vh !important;
}
