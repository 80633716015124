.user-page-title{
    font-size: 1.4rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.user-page-subtitle{
    text-align: left;
    font-size: 1.1rem;
    text-align: left;
}

.btn-add-user{
    font-size: 1.3rem;
    width: 20rem;
    background-color: #00BF16;
}