@media (min-width:1224px) {
    .add-broadcast{
        margin-left: 1rem;
        width: 30rem;
        height: 10rem;
        border: 1px solid black !important;
        border-radius: 3px;
        padding: 1rem;
        font-size: 14px;
        line-height: 25px;
        margin-left: 1.3rem;
    }
}

@media(min-width: 1879px) and (min-width: 800px){
    .add-broadcast{
        margin-left: 1rem;
        width: 30rem;
        height: 10rem;
        border: 1px solid black !important;
        border-radius: 3px;
        padding: 1rem;
        font-size: 14px;
        line-height: 25px;
        margin-left: 1.3rem;
    }
}