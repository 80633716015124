.queue-edit{
    float: right;
    margin-right: -2rem;
    margin-top: -2rem;
    font-size: 1.3rem;

}

.radio-style{
    margin-left: 1rem;
    display: inline;

}
.container {
    display: inline !important;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }


@media (min-width:1224px) {
    .edit-queue-buttons{
        margin-left: 30%;
    }
    
    .edit-queue-buttons button{
        width: 13rem;
    }
    
    .edit-queue-buttons .btn-child-data{
        margin-left: 1rem;
    }
    .family-modals{
        margin-top: -1rem !important;
    }

    .address-queue{
      font-size: 12px;
    }
}


@media(min-width: 1879px) and (min-width: 800px){
    .edit-queue-buttons{
        margin-left: 30%;
    }
    
    .edit-queue-buttons button{
        width: 13rem;
    }
    
    .edit-queue-buttons .btn-child-data{
        margin-left: 1rem;
    }
    .family-modals{
        margin-top: -8rem;
    }

    .overlay-body .css-42igfv{
      margin-left: 37%;
      margin-top: 20%;
    }

    .overlay-body .css-df17o1{
      height: 180%;
    }
    .address-queue{
      font-size: 14px;
    }

}