.modern-radio-button-container {
    padding: .5em 1em;
    border: 1px solid #00000038;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    user-select: none;
  }

  .selected-button {
    border: 1px solid #3FBC07;
    transition: all 0.1s linear;
    color: #3FBC07
  }

  .selected-text {
    transition: all 0.1s linear;
    color: #3FBC07
  }
  
  .radio-button-outer-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #3FBC07;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
  }
  
  .radio-button-inner-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #3FBC07;
    transition: all 0.1s linear;
  }
  .unselected-button {
    border: 2px solid #666;
  }
  .unselected-button-circle {
    width: 0;
    height: 0;
  }
  .helper-button-text {
    /* color: #666; */
    padding-right: 8px;
  }
  