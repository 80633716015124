@media(min-width:700px){
  body {
    background-color: #e9eaec !important;
  }

  .dashboard-card {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    width: 90%;
  }
  .card-icon {
    color: #039beb;
    font-size: 1rem;
    margin-right: 1rem;
  }
  .card-title {
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
  }

  .card-number {
    font-size: 3rem;
    font-weight: bold;
    margin-top: -1rem;
  }
  .patients-total,
  .morning-patients,
  .night-patients {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 95%;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 3rem;
  }
  .patient-recapitulation {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 70%;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    margin-left: -8rem;
  }

  .patients-total {
    margin-right: 1rem;
    margin-left: 0rem;
  }
  .morning-patients {
    margin-left: -8rem;
  }
  .night-patients {
    margin-left: -16rem;
  }
  .dashboard-date {
    font-size: 1rem;
    text-align: left;
    margin-left: 1rem;
    /* margin-top: 2rem; */
  }
}

@media (min-width: 1224px) {
  body {
    background-color: #e9eaec;
  }

  .dashboard-card {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    width: 90%;
  }
  .card-icon {
    color: #039beb;
    font-size: 12px;
    margin-right: 1rem;
  }
  .card-title {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
  }

  .card-number {
    font-size: 3rem;
    font-weight: bold;
    margin-top: -1rem;
  }
  .patients-total,
  .morning-patients,
  .night-patients {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 95%;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 3rem;
  }
  .patient-recapitulation {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 70%;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    margin-left: -8rem;
  }

  .patients-total {
    margin-right: 1rem;
    margin-left: 0rem;
  }
  .morning-patients {
    margin-left: -8rem;
  }
  .night-patients {
    margin-left: -16rem;
  }
  .dashboard-date {
    font-size: 1rem;
    text-align: left;
    margin-left: 1rem;
    /* margin-top: 2rem; */
  }
}

@media (min-width: 1879px) and (min-width: 800px) {
  body {
    background-color: #e9eaec;
  }

  .dashboard-card {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    width: 90% !important;
  }
  .card-icon {
    color: #039beb;
    font-size: 1.3rem;
    margin-right: 1rem;
  }
  .card-title {
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
  }

  .card-number {
    font-size: 3rem;
    font-weight: bold;
    margin-top: -1rem;
  }
  .patients-total,
  .morning-patients,
  .night-patients {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 95%;
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 3rem;
  }

  .patient-recapitulation {
    background-color: white;
    box-shadow: 0px 0px 3px 5px rgba(209, 209, 209, 0.1);
    width: 75%;
    height: 10rem;
    border-radius: 1rem;
    padding: 2rem;
    margin-top: -20rem;
    margin-left: -8rem;
  }
  .patients-total {
    margin-right: -3rem;
    margin-left: 0rem;
  }
  .morning-patients {
    margin-left: -8rem;
  }
  .night-patients {
    margin-left: -16rem;
  }

  .dashboard-date {
    font-size: 1.2rem;
    text-align: left;
    /* margin-top: 2rem; */
  }
}
