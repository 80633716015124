.duplicate-data-container {
  padding-right: 2rem;
  background: white;
  margin-top: -2rem;
  margin-left: -6rem;
  padding-top: 2rem;
  padding-left: 7rem;
  min-height: 100vh;
  padding-bottom: 40px;
}

.header-right-content-wrapper {
  display: flex;
  justify-content: end;
}

.multiple-delete-wrapper {
  display: flex;
  align-items: center;
  gap: 37px;
}

.modal-duplicate-data-certificate > .ant-modal-content {
  border-radius: 24px;
  max-width: 717px;
}

.modal-duplicate-data-certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 654px;
}

.modal-duplicate-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
}

.modal-duplicate-data-description {
  width: 611px;
  color: #747474;
  font-size: 16px;
}
