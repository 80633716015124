.detail-body {
  height: 100%;
  background-color: white;
  padding: 2rem;
}

.patient-title {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
}

.patient-table {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.horizontal-line {
  border: 1px solid #707070;
  opacity: 0.5;
  width: 100% !important;
}

.patient-button {
  width: 100%;
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 14px;
  background-color: #039beb;
}

.patient-button-done {
  width: 100%;
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 14px;
  background-color: #00b113;
}
.patient-button-danger {
  width: 100%;
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 14px;
}
