.card-checkin {
  max-width: 100%;
  overflow: hidden;
  background: #f9dbbf;
  box-shadow: 4px 4px 25px rgba(50, 119, 155, 0.1);
  cursor: pointer;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-checkin-title {
  margin: 0;
  line-height: 97px;
  font-weight: 800;
  font-size: 80px;
  color: #ffffff;
  margin-bottom: 60px;
}

.button-card-checkin {
  width: 100%;
  border: none;
  background: #f06b10;
  font-weight: 500;
}

.button-card-checkin:hover {
  background: #f06a109c;
}

@media (min-width: 768px) {
  .card-checkin {
    border-radius: 16px;
    padding: 24px;
  }

  .card-img-wrapper {
    width: 300px;
    height: 300px;
    border-radius: 16px;
    margin-bottom: 24px;
  }

  .button-card-checkin {
    padding: 12px;
    border-radius: 10px;
    height: 57px;
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .card-checkin {
    border-radius: 24px;
    padding: 24px;
  }

  .button-card-checkin {
    padding: 12px;
    border-radius: 16px;
    height: 67px;
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .card-img-wrapper {
    width: 280px;
    height: 280px;
  }

  .button-card-checkin {
    padding: 12px;
    border-radius: 16px;
    height: 77px;
    font-size: 1.7rem;
  }
}
