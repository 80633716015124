.data-account button{
    width: 7rem;
    height: 2.3rem;
    font-size: 14px;
    margin-top: 3rem;
}

.data-account .save-user{
    margin-left: 9.5rem;
}

.data-account .label-update{
    width: 6rem !important;
}